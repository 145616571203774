<template>
  <div class="recharge-con" :style="!isWeixin_status ? 'padding-top: 50px':''">
    <NavBar 
      v-if="!isWeixin_status" fixed title="充值记录" 
      @click-left="goBack" 
      :left-arrow="isWeixin_status == false">
    </NavBar>
		<Tabs
			:swipe-threshold="6"
       swipeable
			v-model="active"
			class="order-tabs"
			style="z-index:200"
      sticky
		>
			<Tab v-for="(item, idx) in tabs" :key="idx" :title="item" >
        <List 
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
        <div>
        </div>
          <div class="money-item" v-for="(item, idx) in listArr" :key="idx">
            <div class="money-item-top">
              <span>订单号: {{item.ordersn}}</span>
              <span class="money-item-money">+{{Number(item.amount) + Number(item.balance)}}</span>
            </div>
            <div class="money-item-bottom">
              <span>{{item.create_time}}</span>
              <div>
                <Tag v-if="item.pay_status == 1">未支付</Tag>
                <Tag type="primary" v-if="item.pay_status == 2">已完成</Tag>
              </div>
            </div>
          </div>
        </List>
      </Tab>
		</Tabs>
  </div>
</template>
<script>
import { NavBar, List, Tabs, Tab, Tag } from "vant";
import UserInfo from "@/api/user";
export default {
  components: {
    NavBar, List, Tabs, Tab, Tag 
  },
  data() {
    return {
      loading: false,
      finished: false,
      listArr: [],
      active: 0,
      page: 1,
      tabs: ['全部','未支付','完成'],
      account_id: 0,
    }
  },
  watch: {
    active: function() {
      this.page = 1;
      this.listArr = [];
      this.finished = false;
      this.onLoad();
    }
  },
  methods: {
    async onLoad() {
      try {
        if(this.$route.query.hasOwnProperty("account_id")) {
          this.account_id = this.$route.query.account_id
        } else {
          this.account_id = 0;
        }
        const res = await UserInfo.user_recharge(this.page++, 10, this.active, this.account_id);
        if (res.code == 200) {
          if (res.data.length > 0) {
            res.data.forEach(item => {
              this.listArr.push(item);
            });
            // for (let i = 0; i < res.data.length; i++) {
            //   const element = res.data[i];
            //   this.listArr.push(element);
            // }
            this.loading = false;
            if (res.total == this.listArr.length) {
              this.finished = true;
            }
          } else {
            throw(res.msg);
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.recharge-con {
  width: 100%;
  height: 100vh;
  background: rgba(244,244,244,1); 
  overflow-y: scroll;
  overflow-x: hidden;
}
.money-item {
  width: 96%;
  height: 90px;
  display: flex;
  border-radius: 6px;
  margin: 10px auto;
  padding: 10px 14px;
  background: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  .money-item-top,.money-item-bottom {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .money-item-money {
      font-size: 18px;
      color: darkorange;
    }
  }
  .money-item-bottom {
    font-size: 12px;
  }
}
</style>